import React from "react";
import Layout from "../components/layouts";
import BreadCrumb from "../components/common/breadcrumb";
import { graphql } from "gatsby";
import Gallery from "../components/collections/Gallery";
import Details from "../components/collections/Details";
import Seo from "../components/seo";

const Product = ({ data }) => {
  const product = data.shopifyProduct;
  return (
    <Layout navBg="rgba(0,0,0,1)">
      <Seo
        data={{
          title: product.title,
          description: data.description,
        }}
      />

      <div className="my-20 pt-4">
        <div className="relative max-w-[1240px] w-full mx-auto box-border show fadeIn my-5 sm:my-10 px-5 lg:px-10 grid lg:grid-cols-2">
          {/* Gallery */}
          <Gallery product={product} />
          {/* Details */}
          <Details product={product} />
        </div>
        <BreadCrumb
          path={{
            breadLink: [
              { nameLink: "Collections", breadPath: "/collections" },
              {
                nameLink: product.title,
                breadPath: `/product/${product.handle}`,
              },
            ],
          }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      title
      handle
      description
      descriptionHtml
      media {
        preview {
          image {
            src
            altText
            gatsbyImageData(formats: WEBP, layout: CONSTRAINED)
          }
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      options {
        name
        values
      }
      variants {
        shopifyId
        displayName
        availableForSale
        price
        compareAtPrice
        sku
        selectedOptions {
          name
          value
        }
      }
    }
  }
`;

export default Product;
