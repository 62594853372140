import React from "react";
import { getPrice } from "../../utils/getPrice";
import Variants from "./Variants";

const Details = ({ product }) => {
  return (
    <div className="sticky top-20 sm:px-5">
      {/* Product Details */}
      <div className="block mb-8">
        <h1 className="text-2xl md:text-[40px] font-bold md:leading-[50px] uppercase font-primary">
          {product.title}
        </h1>
        <h2 className="text-xl md:text-2xl font-bold my-2">
          {getPrice(product.priceRangeV2)}
        </h2>
        <Variants product={product} />
        <hr className="my-10" />

        <div>
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: product.descriptionHtml,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Details;
