import React, { useMemo } from "react";

const SizeColor = ({
  options,
  variants,
  selectedColor,
  selectedSize,
  quanity,
  setSelectedColor,
  setSelectedSize,
  setQuantity,
}) => {
  const colorOptions = useMemo(
    () => options.find((option) => option.name === "Color")?.values || [],
    [options]
  );

  const sizeOptions = useMemo(
    () => options.find((option) => option.name === "Size")?.values || [],
    [options]
  );

  const getSizesAvailability = (size) => {
    if (selectedColor) {
      const variant = variants.find(
        (variant) =>
          variant.selectedOptions.some(
            (option) =>
              option.name === "Color" && option.value === selectedColor
          ) &&
          variant.selectedOptions.some(
            (option) => option.name === "Size" && option.value === size
          )
      );

      return variant?.availableForSale ? variant.availableForSale : false;
    } else {
      const variant = variants.find((variant) =>
        variant.selectedOptions.some(
          (option) => option.name === "Size" && option.value === size
        )
      );

      return variant?.availableForSale ? variant.availableForSale : false;
    }
  };

  return (
    <div className="flex flex-col gap-y-[18px] mt-[18px]">
      {/* Color */}
      {!!colorOptions.length && (
        <div className="flex gap-[10px] items-center flex-wrap">
          <span className="uppercase text-base sm:text-lg font-secondary w-14">
            Color:
          </span>
          {colorOptions.map((color, key) => (
            <button
              key={key}
              className={`relative hidden sm:block rounded-xs px-2 outline-none text-sm sm:text-base focus:outline-none border border-solid bg-transparent ${
                selectedColor === color
                  ? "border-black bg-black text-white"
                  : "border-secondary text-secondary"
              }`}
              onClick={() => setSelectedColor(color)}
            >
              <span key={key} title={color}>
                {color}
              </span>
            </button>
          ))}

          <select
            name="color"
            id="color"
            className="border border-solid border-black px-4 sm:hidden min-w-[250px]"
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
          >
            {colorOptions.map((color, key) => (
              <option key={key} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Size */}
      {!!sizeOptions.length && (
        <div className="flex gap-[10px] items-center flex-wrap">
          <span className="uppercase text-base sm:text-lg font-secondary w-14">
            Size:
          </span>
          {sizeOptions.map((size, key) => {
            const isAvailable = getSizesAvailability(size);

            return (
              <button
                key={key}
                className={`relative hidden sm:block rounded-xs px-2 outline-none text-sm sm:text-base focus:outline-none border border-solid bg-transparent disabled:bg-secondary ${
                  selectedSize === size
                    ? "border-black bg-black text-white"
                    : "border-secondary text-secondary"
                }`}
                disabled={!isAvailable}
                onClick={() => setSelectedSize(size)}
              >
                {size}
              </button>
            );
          })}
          <select
            name="size"
            id="size"
            className="border border-solid border-black px-4 sm:hidden min-w-[250px]"
            value={selectedSize}
            onChange={(e) => setSelectedSize(e.target.value)}
          >
            {sizeOptions.map((size, key) => (
              <option key={key} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex gap-[10px] items-center">
        <span className="uppercase text-base sm:text-lg font-secondary w-14">
          Quantity:
        </span>

        <div className="border border-solid border-secondary text-black text-center flex justify-between items-center space-x-2 px-2">
          <button
            className="appearance-none"
            disabled={quanity <= 1}
            onClick={() => setQuantity((prevQuantity) => prevQuantity - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <span className="px-4">{quanity}</span>
          <button
            className="appearance-none"
            disabled={quanity >= 10}
            onClick={() => setQuantity((prevQuantity) => prevQuantity + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SizeColor;
