import React, { useMemo, useState } from "react";
import SizeColor from "./SizeColor";
import { useCartStore } from "../../state/cart";

const Variants = ({ product }) => {
  const { addItemToCart, lines, isLoading } = useCartStore((state) => state);

  const [selectedColor, setSelectedColor] = useState(
    (
      product.variants.find((variant) => variant.availableForSale) ??
      product.variants[0]
    ).selectedOptions.find((option) => option.name === "Color")?.value ?? ""
  );

  const [selectedSize, setSelectedSize] = useState(
    (
      product.variants.find((variant) => variant.availableForSale) ??
      product.variants[0]
    ).selectedOptions.find((option) => option.name === "Size")?.value ?? ""
  );
  const [quanity, setQuantity] = useState(1);

  const selectedVariant = useMemo(() => {
    const colorOption = product.options.find(
      (option) => option.name === "Color"
    );
    const sizeOption = product.options.find((option) => option.name === "Size");

    if (colorOption && sizeOption) {
      // Both color and size are available
      const variant = product.variants.find(
        (variant) =>
          variant.availableForSale &&
          variant.selectedOptions.some(
            (option) =>
              option.name === "Color" && option.value === selectedColor
          ) &&
          variant.selectedOptions.some(
            (option) => option.name === "Size" && option.value === selectedSize
          )
      );

      if (variant) {
        return variant;
      } else {
        // If the selected size is not available with the new color,
        // find the first available size for the selected color
        const availableSizesForColor = product.variants
          .filter(
            (variant) =>
              variant.availableForSale &&
              variant.selectedOptions.some(
                (option) =>
                  option.name === "Color" && option.value === selectedColor
              )
          )
          .map(
            (variant) =>
              variant.selectedOptions.find((option) => option.name === "Size")
                ?.value
          );

        if (availableSizesForColor.length > 0) {
          setSelectedSize(availableSizesForColor[0]);
          return product.variants.find(
            (variant) =>
              variant.availableForSale &&
              variant.selectedOptions.some(
                (option) =>
                  option.name === "Color" && option.value === selectedColor
              ) &&
              variant.selectedOptions.some(
                (option) =>
                  option.name === "Size" &&
                  option.value === availableSizesForColor[0]
              )
          );
        }
      }
    } else if (colorOption) {
      // Only color is available
      return product.variants.find(
        (variant) =>
          variant.availableForSale &&
          variant.selectedOptions.some(
            (option) =>
              option.name === "Color" && option.value === selectedColor
          )
      );
    } else if (sizeOption) {
      // Only size is available
      return product.variants.find(
        (variant) =>
          variant.availableForSale &&
          variant.selectedOptions.some(
            (option) => option.name === "Size" && option.value === selectedSize
          )
      );
    } else {
      // Neither color nor size is available
      return (
        product.variants.find((variant) => variant.availableForSale) ||
        product.variants[0]
      ); // Return the first available variant or the first variant if none are available
    }
  }, [selectedColor, selectedSize]);

  async function handleAddToCart() {
    const response = await addItemToCart({
      id: selectedVariant.shopifyId,
      quantity: 1,
    });
  }

  console.log(lines);
  console.log(selectedVariant);

  return (
    <div>
      <SizeColor
        selectedColor={selectedColor}
        selectedSize={selectedSize}
        quanity={quanity}
        setSelectedColor={setSelectedColor}
        setSelectedSize={setSelectedSize}
        setQuantity={setQuantity}
        options={product.options}
        variants={product.variants}
      />

      <button
        onClick={handleAddToCart}
        disabled={isLoading || !selectedVariant?.availableForSale}
        className={`inline-block cursor-pointer px-[30px] leading-[38px] font-extrabold uppercase rounded-tl-[27px] rounded-br-[27px] text-center no-underline
        whitespace-nowrap bg-white btn-secondary border border-solid mt-10 box-border transition-all duration-200 ease-linear max-w-sm w-full disabled:cursor-default`}
        aria-label="Add to cart"
      >
        Add to cart
      </button>
    </div>
  );
};

export default Variants;
