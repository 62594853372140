import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { Magnifier } from "react-image-magnifiers";
import useEmblaCarousel from "embla-carousel-react";

const Gallery = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [emblaRef] = useEmblaCarousel({
    align: "start",
    breakpoints: {
      "(min-width: 768px)": { active: false },
    },
  });

  useEffect(() => {
    if (product) {
      setIsOpen(false);
      setPhotoIndex(0);
    }
  }, [product]);

  function moveNextPhoto() {
    setPhotoIndex((photoIndex + 1) % product.media.length);
  }

  function movePrevPhoto() {
    setPhotoIndex(
      (photoIndex + product.media.length - 1) % product.media.length
    );
  }

  function openLightBox() {
    let index = parseInt(
      // @ts-ignore
      document.querySelector(".product-main-image").getAttribute("index")
    );

    if (!index) {
      index = 0;
    }
    setIsOpen(true);
    setPhotoIndex(index);
  }

  function closeLightBox() {
    setIsOpen(false);
  }

  function changeBgImage(e, image, index) {
    let imgs = document.querySelectorAll(".product-main-image img");
    for (let i = 0; i < imgs.length; i++) {
      // @ts-ignore
      imgs[i].src = image;
    }
    // @ts-ignore
    document
      .querySelector(".product-image-gallery .active")
      .classList.remove("active");
    // @ts-ignore
    document.querySelector(".product-main-image").setAttribute("index", index);
    e.currentTarget.classList.add("active");
  }

  return (
    <div className="w-full sm:px-5 max-w-xl">
      <div className="relative block mb-8">
        <div className="flex flex-wrap m-0">
          <figure
            className="product-main-image relative mb-[10px] rounded-[10px] w-full"
            tabIndex={0}
          >
            {product.media.length > 0 ? (
              <Magnifier
                imageSrc={product.media[0]?.preview.image.src}
                imageAlt="product"
                largeImageSrc={product.media[0]?.preview.image.src} // Optional
                dragToMove={false}
                mouseActivation="click"
                cursorStyleActive="crosshair"
                className="bg-[#f4f4f4] rounded-[10px] overflow-hidden relative object-contain zoom-image border border-solid border-primary"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  paddingTop: `100%`,
                }}
              />
            ) : (
              <div
                className="bg-[#f4f4f4] rounded-[10px] overflow-hidden p-relative"
                style={{
                  width: "100%",
                  height: "100%",
                  paddingTop: `100%`,
                }}
              ></div>
            )}

            <button
              id="btn-product-gallery"
              className="btn-product-gallery p-2"
              onClick={openLightBox}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-3"
              >
                <path d="m13.28 7.78 3.22-3.22v2.69a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0 0 1.5h2.69l-3.22 3.22a.75.75 0 0 0 1.06 1.06ZM2 17.25v-4.5a.75.75 0 0 1 1.5 0v2.69l3.22-3.22a.75.75 0 0 1 1.06 1.06L4.56 16.5h2.69a.75.75 0 0 1 0 1.5h-4.5a.747.747 0 0 1-.75-.75ZM12.22 13.28l3.22 3.22h-2.69a.75.75 0 0 0 0 1.5h4.5a.747.747 0 0 0 .75-.75v-4.5a.75.75 0 0 0-1.5 0v2.69l-3.22-3.22a.75.75 0 1 0-1.06 1.06ZM3.5 4.56l3.22 3.22a.75.75 0 0 0 1.06-1.06L4.56 3.5h2.69a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0V4.56Z" />
              </svg>
            </button>
          </figure>

          <div
            id="product-zoom-gallery"
            className="product-image-gallery mr-0 ml-0 overflow-hidden"
            ref={emblaRef}
          >
            <div className="flex md:grid md:grid-cols-2 gap-2">
              {product.media.length > 0 &&
                product.media.map((media, key) => (
                  <div
                    key={key}
                    className="shrink-0 grow-0 basis-[45%] mb-1 border border-solid border-primary rounded-[10px] overflow-hidden"
                  >
                    <button
                      className="active product-masonry-item p-0 w-100"
                      onClick={(e) =>
                        changeBgImage(e, media.preview.image.src, 0)
                      }
                    >
                      <GatsbyImage
                        image={media.preview.image.gatsbyImageData}
                        alt={product.title}
                        objectFit="cover"
                        className="w-100"
                      />
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {isOpen ? (
        <Lightbox
          mainSrc={product.media[photoIndex].preview.image.src}
          nextSrc={
            product.media[(photoIndex + 1) % product.media.length].preview.image
              .src
          }
          prevSrc={
            product.media[
              (photoIndex + product.media.length - 1) % product.media.length
            ].preview.image.src
          }
          onCloseRequest={closeLightBox}
          onMovePrevRequest={moveNextPhoto}
          onMoveNextRequest={movePrevPhoto}
          reactModalStyle={{
            overlay: {
              zIndex: 1041,
            },
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Gallery;
